export function initFB() {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;

    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };

    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
  })(window, document);
  window.fbq('init', '518757018285816');
}
export function pageview(path: string) {
  window.fbq('track', 'PageView');
}

type Items = {
  item_name: string;
  item_id: string;
  item_brand: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_list_name: string;
  item_list_id: string;
  index?: number;
  quantity: number;
}[];

export function trackOnClick(items: Items) {
  window.dataLayer?.push({ ecommerce: null });
  window.dataLayer?.push({
    event: 'EEC_select_item',
    event_name: 'select_item',
    ecommerce: {
      items,
    },
  });
}
