export default function asyncLoad(u: string, c?: (...args: Array<any>) => any) {
  var d = document,
    t = 'script',
    o = d.createElement(t),
    s = d.getElementsByTagName(t)[0];
  o.src = u.charAt(0) === '/' ? u : '//' + u;

  if (c) {
    o.addEventListener(
      'load',
      function (e) {
        c(null, e);
      },
      false,
    );
    o.addEventListener(
      'error',
      function (e: Error) {
        c(e);
      },
      false,
    );
  }

  if (s.parentNode) {
    s.parentNode.insertBefore(o, s);
  }
}
