'use client';
import { useEffect, useRef, type JSX } from 'react';
import { topNaviTags } from '../../common/headerData';
import { classNames } from '../../util/classNames';

let notYetSendEcommerceEvents: any[] = [];
const pushedEcommerceEventIds: string[] = [];

const pushEcommerceEvents = () => {
  if (notYetSendEcommerceEvents.length === 0) return;
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'EEC_view_item_list',
    event_name: 'view_item_list',
    ecommerce: {
      items: notYetSendEcommerceEvents,
    },
  });
  notYetSendEcommerceEvents = [];
};

if (typeof window !== 'undefined') {
  addEventListener('beforeunload', () => {
    pushEcommerceEvents();
  });

  addEventListener('visibilitychange', () => {
    if (window.document.visibilityState === 'hidden') {
      pushEcommerceEvents();
    }
  });
}

type Props = {
  id: string;
  title: string;
  tags?: TagDto[];
  children: JSX.Element;
  index: number;
  listType: string;
  className?: string;
};

export function CardTracker(props: Props) {
  const { title, id, tags = [], className } = props;
  const ref = useRef<HTMLElement>(null);
  useEffect(() => {
    const currrentArticle = ref.current;
    if (!currrentArticle) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        //TO-DO: When going from homepage to Article and than back to homepage, than entry.isIntersecting is false on the visible Articles, but it should be true, because its intersecting. So the first 6 Articles wont be tracked in this scenario
        if (entry.isIntersecting && !pushedEcommerceEventIds.includes(id)) {
          pushedEcommerceEventIds.push(id);
          notYetSendEcommerceEvents.push({
            item_name: title,
            item_id: id,
            item_brand: 'standard',
            item_category: tags
              .filter((tag) => topNaviTags.has(tag.slug))
              .map((tag) => tag.slug)
              .join(','),
            item_category2: tags
              .filter((tag) => tag.kind === 'promi')
              .map((tag) => tag.slug)
              .join(','),
            item_category3: tags
              .filter((tag) => tag.kind !== 'promi')
              .filter((tag) => !topNaviTags.has(tag.slug))
              .map((tag) => tag.slug)
              .join(','),
            item_list_name: props.listType,
            item_list_id: props.listType,
            index: props.index,
            quantity: 1,
          });
          if (notYetSendEcommerceEvents.length >= 25) {
            pushEcommerceEvents();
          }
        }
      },
      {
        threshold: 0.6,
      },
    );
    if (currrentArticle) {
      observer.observe(currrentArticle);
    }
    return () => {
      pushEcommerceEvents();
      observer.unobserve(currrentArticle);
    };
  }, [id, props.id, props.index, props.listType, tags, title]);

  return (
    <article
      className={classNames(className ? className : 'flex w-full')}
      ref={ref}
    >
      {props.children}
    </article>
  );
}
