let topics: Array<{
  id: string;
  name: string;
  slug: string;
}> = [
  {
    id: '09c7e3a3-d640-4842-a8f6-2223a662e7c8',
    name: 'Royals',
    slug: 'royals',
  },
  {
    id: '6aef7363-dab1-4e6d-981e-6edeff2d3250',
    name: 'Internationale Stars',
    slug: 'internationale-stars',
  },
  {
    id: '6e821d5c-c124-4410-940d-4a6a68b0ea78',
    name: 'Mama & Baby',
    slug: 'mama-und-baby',
  },
  {
    id: 'ac85acac-a85f-432d-a0dd-97ce4b681a2d',
    name: 'Reality-TV',
    slug: 'reality-tv',
  },
  {
    id: '6f427ad8-e1ab-48ce-9400-c05338288e60',
    name: 'Deutsche Stars',
    slug: 'deutsche-stars',
  },
  {
    id: 'd07b7127-8807-40b5-8e30-d63283925c55',
    name: 'Liebe',
    slug: 'liebe',
  },
  {
    id: '17aa2b61-013f-4191-be46-a065e0e8c6e0',
    name: 'Promiflash Exklusiv',
    slug: 'promiflash-exklusiv',
  },
  {
    id: '5e12b39e-fa6a-4e97-86c7-126c7bc1835d',
    name: 'Beauty',
    slug: 'beauty',
  },
  {
    id: 'ea1cafbd-8b8b-4a28-81b0-e7d5972d2ac6',
    name: 'Fitness',
    slug: 'fitness',
  },
  {
    id: 'bc30ebbc-837e-445e-b1b8-ab3691382447',
    name: 'Fashion',
    slug: 'fashion',
  },
  {
    id: '2245cbe8-1049-4ac6-b260-9f1b7b47f0a9',
    name: 'Filme & Serien',
    slug: 'filme-und-serien',
  },
  {
    id: 'f44dd493-a76a-4821-b0f0-8251e45dab17',
    name: 'Webstars',
    slug: 'webstars',
  },
];

let burgerMenuTopics: Array<{
  name: string;
  slug: string;
}> = [
  {
    name: 'Royals',
    slug: 'royals',
  },
  {
    name: 'Stars',
    slug: 'stars',
  },
  {
    name: 'Filme & Serien',
    slug: 'filme-und-serien',
  },
  {
    name: 'Lifestyle',
    slug: 'lifestyle',
  },
  {
    name: 'Familie & Liebe',
    slug: 'familie-und-liebe',
  },
  {
    name: 'Promiflash Exklusiv',
    slug: 'promiflash-exklusiv',
  },
];

let highlightedLinks: Array<{
  name: string;
  route: string;
}> = [
  {
    name: 'Alle Themen auf Promiflash',
    route: '/thema/',
  },
  {
    name: 'Jobs',
    route: '/jobs/',
  },
  {
    name: 'App runterladen',
    route: '/app/',
  },
  {
    name: 'Team',
    route: '/team/',
  },
  {
    name: 'Redaktionelle Richtlinien',
    route: '/redaktionelle-richtlinien/',
  },
  {
    name: 'Impressum',
    route: '/impressum/',
  },
  {
    name: 'Datenschutzerklärung',
    route: '/datenschutzerklaerung/',
  },
  {
    name: 'Nutzungsbedingungen',
    route: '/agb/',
  },
];

/**
 * Set of all top navigation tags slugs
 */
export const topNaviTags = new Set(topics.map((topic) => topic.slug));

export default {
  topics,
  highlightedLinks,
  burgerMenuTopics,
};
