/*
 ** Media Queries
 */
export const mediaQuery = {
  minWidthDesktop: 640,
  minWidthDesktop2: 1145,
  minWidthDesktop3: 1280,
};
export const minWidthDesktop = 640;
export const minWidthDesktop2 = 1145;
export const minWidthDesktop3 = 1280;

export const mediaQueries = {
  minWidthDesktop: `min-width: ${minWidthDesktop}px`,
  minWidthDesktop2: `min-width: ${minWidthDesktop2}px`,
  minWidthDesktop3: `min-width: ${minWidthDesktop3}px`,
};

/*
 **  Colors
 */
export const primaryColor = '#FF0066';
export const secundaryColor = '#A40041';
// tintColor -> button background / links (clickable Text)
export const tintColor = primaryColor;
export const tintColorHover = secundaryColor;
export const selectionColor = '#FFB3d1';
// white -> page background / button text
export const white = '#FFFFFF';
// lightGrey -> borders
export const lightGrey = '#F3F3F3';
// grey -> attributes
export const grey = '#A3A3A3';
// darkGrey -> text
export const darkGrey = '#333333';

/*
 **  Font defaults
 */
export const fontSizes = {
  smaller: 13,
  small: 16,
  medium: 19.5,
  large: 24,
  larger: 36,
  largerMobile: 28,
};
export const fontWeights = {
  lighter: 'lighter',
  normal: 'normal',
  bold: '600',
  bolder: 'bold',
};

/*
 **  Text defaults
 */
export const h1 = {
  fontSize: fontSizes.larger,
  fontSizeMobile: fontSizes.largerMobile,
  fontWeight: fontWeights.bolder,
  color: darkGrey,
};
export const h2 = {
  fontSize: fontSizes.large,
  fontWeight: fontWeights.bolder,
  color: darkGrey,
};
export const h3 = {
  fontSize: fontSizes.medium,
  fontWeight: fontWeights.bolder,
  color: darkGrey,
};
export const subheadline = {
  fontSize: fontSizes.medium,
  fontWeight: fontWeights.normal,
  color: grey,
};
export const a = {
  fontSize: fontSizes.medium,
  fontWeight: fontWeights.normal,
  color: tintColor,
};
export const aHover = {
  fontSize: fontSizes.medium,
  fontWeight: fontWeights.normal,
  color: tintColorHover,
};
export const p = {
  fontSize: fontSizes.medium,
  fontWeight: fontWeights.normal,
  color: tintColorHover,
};
export const b = {
  fontWeight: fontWeights.bold,
};
export const attributes = {
  fontSize: fontSizes.smaller,
  fontWeight: fontWeights.normal,
  color: grey,
};

/*
 **  Buttons
 */
export const button = {
  color: grey,
  fontWeight: fontWeights.bold,
  fontSize: fontSizes.small,
};
export const buttonHover = {
  color: grey,
  fontWeight: fontWeights.bold,
};
export const buttonHighlight = {
  color: white,
  colorHover: white,
  backgroundColor: tintColor,
  backgroundColorDisabled: grey,
  backgroundColorHover: tintColorHover,
  borderRadius: 0,
  padding: 12,
  fontWeight: fontWeights.bold,
  fontSize: fontSizes.small,
};

/*
 **  Cards
 */
export const card = {
  paddingHorizontal: 20,
  paddingVertical: 20,
  paddingHorizontalDesktop: 20,
  paddingVerticalDesktop: 20,
  color: darkGrey,
  colorHover: tintColor,
  backgroundColorHover: lightGrey,
};
